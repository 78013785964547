@import '../../../assets/scss/1-settings/1-settings.scss';
@import '../../../assets/scss/2-tools/2-tools.scss';

.sz-aside {
  height: 100%;
  top: 0;
  bottom: 0;
  box-shadow: 0 7px 10px rgba(0, 0, 0, 0.27);
  transition: width 0.5s ease;
  @at-root #{&}__item {
    position: relative;
    width: 100%;
    height: 60px;
    padding-left: 1.25rem;
    transition: 0.5s ease;
    color: var(--secondary);
    &.active &--before {
      width: 4px;
      background: var(--primary-dark);
    }
    &--before {
      position: absolute;
      width: 0;
      top: 0;
      bottom: 0;
      left: 0;
      transition: ease 0.3s width;
    }
    &:hover {
      background: var(--gray-100);
    }
    &.active {
      background: var(--gray-200);
      color: var(--secondary);
    }
  }
  @at-root #{&}__footer {
    span {
      font-size: 20px;
    }
  }
  @at-root #{&}__item--title {
    padding-left: $default-spacing * 2;
    min-width: 150px;
  }
  @at-root #{&}__item--icon {
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 768px) {
  .sz-aside.open,
  .sz-aside.open.sz-aside-enter-done {
    width: 100%;
    transition: width 0.5s ease;
    .sz-aside__item {
      opacity: 1;
      padding: 0 $default-spacing * 3;
      transition: 0.5s ease;
    }
  }
  .sz-aside.sz-aside-exit-done {
    width: 0;
    transition: width 0.5s ease;
    .sz-aside__item {
      opacity: 0;
      transition: 0.5s ease;
      &.active:after {
        content: none;
      }
    }
  }
}
@media screen and (min-width: 768px) {
  .sz-aside.open,
  .sz-aside.sz-aside-enter-done {
    width: 200px;
  }
  .sz-aside:not(.open),
  .sz-aside.sz-aside-exit-done {
    width: 62px;
    .sz-aside__item {
      opacity: 1;
      transition: 0.5s ease;
    }
    .sz-aside__item--title {
      display: none;
      // opacity: 0;
      // transition: opacity 0.25s ease;
    }
  }
}
