@import '../../../assets/scss/1-settings/1-settings.scss';
@import '../../../assets/scss/2-tools/2-tools.scss';

.sz-header-stepper {
  height: 40px;
  margin-bottom: 1px;
  @at-root #{&}__step {
    line-height: 1;
    color: var(--disabled);
    &.sz-step-rounded.underline::after {
      border-radius: 2px;
    }
    span.rounded-circle,
    span.icon {
      width: 24px;
      height: 24px;
      justify-content: center;
      align-items: center;
    }
    span:not(.rounded-circle) {
      font-size: $font-size-base * 1.15;
    }
    &:after {
      content: '';
      position: absolute;
      width: 0;
      height: 4px;
      bottom: 0;
      left: 0;
      background: var(--primary-brand);
      transition: 0.3s ease;
    }
    &.active {
      color: var(--secondary);
      &:not(:last-child) {
        span.rounded-circle {
          background-color: var(--primary-brand);
        }
      }
    }
    &.underline {
      &::after {
        width: 100%;
      }
    }
    &.untouched {
      color: var(--gray-600);
      &::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 4px;
        bottom: 0;
        left: 0;
        background: var(--blue-3);
        transition: 0.3s ease;
        border-radius: 2px;
      }
    }
  }

  @at-root #{&}_title-progress-bar {
    color: var(--blue-1);
  }
  .sz-stepper_progress-bar-rounded {
    border-radius: 10px;
    height: 0.75rem;
    padding: 2px;
    border: 1px solid var(--blue-4);
    .progress-bar {
      background-color: var(--primary-brand);
      border-radius: 10px;
    }
  }
  .simple-title {
    top: 0;
  }
  .stepper-bar {
    width: 75px;
    height: 7px;
  }
}
.sz-body-stepper {
  padding: $default-spacing * 1.875 $default-spacing * 2;
  @at-root #{&}__step {
    display: none;
    &.active {
      display: block;
    }
  }
}

@media screen and (min-width: 768px) {
  .sz-body-stepper {
    padding: $default-spacing * 3.75 $default-spacing * 5;
  }
  .sz-header-stepper {
    height: 73px !important;
    padding-top: 8px !important;
    opacity: 0.95;
  }
}
