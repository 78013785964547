@import '../../../assets/scss/1-settings/1-settings.scss';
@import '../../../assets/scss/2-tools/2-tools.scss';

.sz-switch-selector-wrapper {
  display: flex;
  justify-content: center;
  width: fit-content;
  border-radius: 20px;
  overflow: hidden;
  padding: 2px;
  border: 1px solid var(--secondary);
  .select-option {
    display: flex;
    border-radius: 20px;
    height: 100%;
    font-family: $font-family-regular;
    .option-label {
      justify-content: center;
      min-width: 35px;
      font-family: $font-family-regular;
      font-size: 14px;
      margin: 0 2px;
      padding: 4px 5px 5px;
      display: flex;
      align-items: center;
      border-radius: 20px;
      cursor: pointer;
      position: relative;
      height: 100%;
      color: var(--secondary);
      input {
        position: absolute;
        height: 0;
      }
      &.selected-option,
      &.selected-initial {
        background-color: var(--secondary);
        color: white;
      }
      &.selected-initial {
        padding-left: 7px;
        padding-right: 7px;
      }
    }
  }
}
