.Sz-pagination {
  .page-item {
    line-height: 27px;
    .page-link {
      padding: 0;
      background: transparent;
      border: none;
      margin: 0 5px;
      color: var(--gray);
      text-decoration: none;
      &:focus {
        box-shadow: none;
      }
    }
    &.active {
      .page-link {
        background: transparent;
        border: none;
        font-weight: bold;
        color: var(--secondary);
      }
    }
  }
}
