@import '../../../assets/scss/1-settings/1-settings.scss';
@import '../../../assets/scss/2-tools/2-tools.scss';

.sz-button-group {
  @at-root #{&}__btn-choose.btn {
    background: #ffffff !important;
    box-shadow: none;
    border: 1px solid var(--primary-dark) !important;
    flex: 1 !important;
    &:hover,
    &.focus {
      box-shadow: none;
    }
    &:hover::after,
    &.active::after {
      content: '';
      opacity: 0.1 !important;
      background: var(--primary-dark) !important;
      color: var(--secondary) !important;
      box-shadow: none !important;
      transform: scale(100%, 100%);
      border-radius: 0;
    }
  }
  @at-root #{&}__btn-choose_icon {
    width: 58px;
    right: 0;
    top: 0;
    bottom: 0;
    background: var(--light);
  }
  @at-root #{&}__btn-choose.active #{&}__btn-choose_icon {
    background: var(--light);
  }
}
