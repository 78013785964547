@import '../../../assets/scss/1-settings/1-settings.scss';

.sz-helper-wrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 150;
  @at-root #{&}.inactive {
    display: none;
  }
  @at-root #{&}.active {
    display: block;
  }
}

.sz-helper-float-btn {
  width: 55px;
  height: 55px;
  z-index: 140;
  background-color: var(--yellow);
  border: none;
  i {
    z-index: 1;
  }
  &::before {
    content: '';
    position: absolute;
    background-color: #ffb90a;
    border-radius: 50%;
    width: 0;
    height: 0;
    opacity: 0;
    transition: 0.5s ease;
  }
  &:hover {
    &::before {
      opacity: 1;
      width: 55px;
      height: 55px;
    }
  }
}

.sz-helper-btn {
  min-width: 150px;
  height: 42px;
  background-color: #ffecbe;
  border: 1px solid var(--yellow);
  border-radius: 25px;
  font-size: 0.87rem;
  position: relative;
  overflow: hidden;
  span,
  i {
    z-index: 1;
  }
  &::before {
    content: '';
    position: absolute;
    background-color: #ffdd8a;
    border-radius: 50%;
    width: 0;
    height: 0;
    opacity: 0;
    transition: 0.5s ease;
  }
  &:hover {
    &::before {
      opacity: 1;
      width: 150px;
      height: 150px;
    }
  }
}

.sz-helper {
  background-color: #ffecbe;
  position: relative;
  overflow: hidden;
  z-index: 2;
  .sz-circle {
    background-color: var(--yellow);
    border-radius: 50%;
    z-index: -1;
    position: absolute;
    height: 280px;
    width: 280px;
    top: -80px;
    left: -165px;
    @media (min-width: 768px) {
      height: 388px;
      width: 388px;
      top: -95px;
      left: -195px;
    }
  }
  .sz-icon-container {
    z-index: -1;
    position: absolute;
    @media (min-width: 768px) {
      top: 75px;
      right: 53px;
    }
    top: 73px;
    right: 33px;
  }
  .sz-icon {
    font-size: 80px;
    @media (min-width: 768px) {
      font-size: 138px;
    }
    color: var(--yellow-light);
  }
  .sz-content-container {
    z-index: 2;
  }
  .sz-img-container {
    display: flex;
    height: 100%;
    align-items: center;
  }
  .sz-banner-img {
    position: absolute;
    z-index: -1;
    right: -200px;
    top: 0;
    bottom: 0;
  }
}
