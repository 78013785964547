@import '../../../assets/scss/1-settings/1-settings.scss';

.sz-select__menu-list {
  max-height: 270px;
  overflow-y: auto;
  .sz-list_item {
    .sz-data {
      z-index: 1;
    }
    .sz-checkbox {
      label {
        display: flex;
        align-items: center;
        &::before,
        &::after {
          top: inherit;
          left: -0.5rem;
        }
      }
    }
  }
}
