.sz-list {
  @at-root #{&}_item {
    min-height: 56px;
    &-text {
      z-index: 10;
    }
    &::before {
      content: none;
      background: var(--gray-100);
      border: 2px solid var(--gray-100);
      border-radius: 2px;
      position: absolute;
      top: 0.3rem;
      bottom: 0.3rem;
      left: 0;
      right: 0;
      z-index: 0;
    }
    &:hover::before {
      content: '';
    }
    &:active::before {
      background: var(--gray-0);
      border: 2px solid var(--gray-0);
    }
  }
  @at-root #{&}.list-group-horizontal #{&}_item.list-group-item {
    border: none;
    &.active {
      background: none;
      border: none;
      color: var(--text-primary-dark);
      margin-left: 0;
    }
    &:hover {
      .sz-list_item-border {
        left: 0 !important;
        right: 0 !important;
      }
    }
    .sz-list_item-border {
      position: absolute;
      bottom: 0;
      height: 2px;
      background-color: black;
    }
  }
}
